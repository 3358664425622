//Библиотеки
import { Pages } from "../../inc/Pages";
//Административная панель
//Представления - пользователи
import UserIndex from "./admin/user/users";
import UserShow from "./admin/user/users/show";
//Представления - роли
import RoleIndex from "./admin/user/roles";
import RoleShow from "./admin/user/roles/show";
//Представления - категории
import CategoryIndex from "./admin/content/categories/index";
import CategoryShow from "./admin/content/categories/show";
//Представления - акции
import PromotionIndex from "./admin/content/promotions/index";
import PromotionShow from "./admin/content/promotions/show"; 
//Представления - продукты
import ProductIndex from "./admin/content/products/index";
import ProductShow from "./admin/content/products/show";
//Представления - новости
import NewsIndex from "./admin/content/news";
import NewsShow from "./admin/content/news/show";
//Представления - промокоды
import PromocodeIndex from "./admin/content/promocodes/index";
import PromocodeShow from "./admin/content/promocodes/show";
//Представления - Анкеты
import SurveyIndex from "./admin/management/surveys/index";
import SurveyShow from "./admin/management/surveys/show";
//Представления - депозиты
import BalanceIndex from "./admin/booking/balances";
//Представления - заказы сертификатов
import OrderCertificateIndex from "./admin/orders/certificates";
//Представления - заказы полетного времени
import OrderBookingIndex from "./admin/orders/bookings";
import OrderBookingShow from "./admin/orders/bookings/show";
//Представления - заказы покупок депозитов
import OrderBalanceIndex from "./admin/orders/balances";
//Представления - разница депозитов
import BalanceDiffIndex from "./admin/booking/balances-diff";
//Представления - операции над депозитами
import BookingTimeIndex from "./admin/booking/times";
//Представления - бронирования
import BookingIndex from "./admin/booking/bookings";
import BookingShow from "./admin/booking/bookings/show";
//Представления - платформы
import PlatformIndex from "./admin/event/platforms";
import PlatformShow from "./admin/event/platforms/show";
//Представления - банеры
import BannerIndex from "./admin/event/banners";
import BannerShow from "./admin/event/banners/show";
//Представления - заказы соревнования
import OrderCompetitionsIndex from "./admin/orders/competitions";
import OrderCompetitionsTeamShow from "./admin/orders/competitions/teamShow";
import OrderCertificateShow from "./admin/orders/certificates/show";
//Представления - календари
import CalendarIndex from "./admin/event/calendar/calendars";
import CalendarShow from "./admin/event/calendar/calendars/show";
//Представления - календари категорий
import CategoryCalendarIndex from "./admin/event/calendar/category";
import CategoryCalendarShow from "./admin/event/calendar/category/show";
//Представления - аналитика
import AnalyticOrderIndex from "./admin/statistic/analytics/orders";
import AnalyticBookingIndex from "./admin/statistic/analytics/bookings";
//Представления - заказы амо
import OrderAmoIndex from "./admin/orders/amo";
//Представления - параметры
import ParametrIndex from "./admin/statistic/parameters";
//Представлеия - event команды
import CompetitionTeamIndex from "./admin/event/competitions/index.js";
import CompetitionTeamShow from "./admin/event/competitions/show.js";

//Личный кабинет
//Представления - Сертификаты
import AccountCertificateIndex from "./account/orders/certificates";
//Представления - Бронирования
import AccountBookingIndex from "./account/bookings/bookings";
//Представления - Депозиты
import AccountBalanceIndex from "./account/bookings/balances";
//Представления - Операции по депозитам
import AccountTimeIndex from "./account/bookings/times";
//Главная
import AccountHomeIndex from "./account/home";

//Общие для авторизованных
import ProfileShow from "./public/profile/show";
import ClientIndex from "./admin/management/clients";
import ClientShow from "./admin/management/clients/show";
import AccountBookingShow from "./account/bookings/bookings/show";


let pages = new Pages();

//Административная панель
let AdminPrefixPermission = 'admin.';
// Управление клиентами
pages.add('authorized.admin.management.clients', {index: ClientIndex, show: ClientShow, create: ClientShow}, `${AdminPrefixPermission}users`);
//Маршруты по пользователю
pages.add('authorized.admin.user.users', {index: UserIndex, show: UserShow, create: UserShow}, `${AdminPrefixPermission}users`);
pages.add('authorized.admin.user.roles', {index: RoleIndex, show: RoleShow, create: RoleShow}, `${AdminPrefixPermission}roles`);
//Маршруты по контенту
pages.add('authorized.admin.content.products', {index: ProductIndex, show: ProductShow, create: ProductShow}, `${AdminPrefixPermission}products`);
pages.add('authorized.admin.content.promocodes', {index: PromocodeIndex, show: PromocodeShow, create: PromocodeShow}, `${AdminPrefixPermission}promocodes`);
pages.add('authorized.admin.content.categories', {index: CategoryIndex, show: CategoryShow, create: CategoryShow}, `${AdminPrefixPermission}categories`);
pages.add('authorized.admin.content.promotions', {index: PromotionIndex, show: PromotionShow, create: PromotionShow}, `${AdminPrefixPermission}promotions`);
pages.add('authorized.admin.content.news', {index: NewsIndex, show: NewsShow, create: NewsShow}, `${AdminPrefixPermission}news`);
//Маршруты по бронированию
pages.add('authorized.admin.booking.balances', {index: BalanceIndex}, `${AdminPrefixPermission}booking.balance`);
pages.add('authorized.admin.booking.balance-diff', {index: BalanceDiffIndex}, `${AdminPrefixPermission}booking.balance-difference`);
pages.add('authorized.admin.booking.times', {index: BookingTimeIndex}, `${AdminPrefixPermission}booking.times`);
pages.add('authorized.admin.booking.bookings', {index: BookingIndex, show: BookingShow }, `${AdminPrefixPermission}booking.bookings`);
//Маршруты по заказам
pages.add('authorized.admin.order.amo', {index: OrderAmoIndex}, `${AdminPrefixPermission}order.amo`);
pages.add('authorized.admin.order.certificates', {index: OrderCertificateIndex, show: OrderCertificateShow, create: OrderCertificateShow}, `${AdminPrefixPermission}order.certificates`);
pages.add('authorized.admin.order.bookings', {index: OrderBookingIndex, show: OrderBookingShow, create: OrderBookingShow}, `${AdminPrefixPermission}order.bookings`);
pages.add('authorized.admin.order.balances', {index: OrderBalanceIndex}, `${AdminPrefixPermission}order.balances`);
pages.add('authorized.admin.order.competitions', {index: OrderCompetitionsIndex, teamShow: OrderCompetitionsTeamShow,}, `${AdminPrefixPermission}order.competitions`);
//Маршруты по мероприятиям
pages.add('authorized.admin.event.platforms', {index: PlatformIndex, show: PlatformShow, create: PlatformShow}, `${AdminPrefixPermission}platforms`);
pages.add('authorized.admin.event.banners', {index: BannerIndex, show: BannerShow, create: BannerShow}, `${AdminPrefixPermission}banners`);
pages.add('authorized.admin.event.calendar.calendars', {index: CalendarIndex, show: CalendarShow, create: CalendarShow}, `${AdminPrefixPermission}calendars`);
pages.add('authorized.admin.event.calendar.categories', {index: CategoryCalendarIndex, show: CategoryCalendarShow, create: CategoryCalendarShow}, `${AdminPrefixPermission}calendars`);
pages.add('authorized.admin.event.competition.teams', {index: CompetitionTeamIndex, show: CompetitionTeamShow}, `${AdminPrefixPermission}order.competitions`);
//Маршруты по клиентам
pages.add('authorized.admin.management.surveys', {index: SurveyIndex, show: SurveyShow, create: SurveyShow}, `${AdminPrefixPermission}management.surveys`);
//Статистика
pages.add('authorized.admin.statistic.analytics.booking', {index: AnalyticBookingIndex}, `${AdminPrefixPermission}statistic.analytics`);
pages.add('authorized.admin.statistic.analytics.order', {index: AnalyticOrderIndex}, `${AdminPrefixPermission}statistic.analytics`);
pages.add('authorized.admin.statistic.parameters', {index: ParametrIndex}, `${AdminPrefixPermission}statistic.parameters`);

//Личный кабинет
let AccountPrefixPermission = 'account.';
//Маршруты по сертификатам (заказы)
pages.add('authorized.account.order.certificates', {index: AccountCertificateIndex}, `${AccountPrefixPermission}order.certificates`);
//Маршруты по бронированием
pages.add('authorized.account.booking.bookings', {index: AccountBookingIndex, show: AccountBookingShow}, `${AccountPrefixPermission}booking.bookings`);
//Маршруты по депозитам
pages.add('authorized.account.booking.balances', {index: AccountBalanceIndex}, `${AccountPrefixPermission}booking.balances`);
//Маршруты по операциям по депозитам
pages.add('authorized.account.booking.times', {index: AccountTimeIndex}, `${AccountPrefixPermission}booking.times`);
//Главная
pages.add('authorized.account.home', {index: AccountHomeIndex}, `${AccountPrefixPermission}booking.times`)

//Общие для авторизованных
let CommonPermission = 'common.';
//Профиль
pages.add('authorized.common.profile', {show: ProfileShow}, `${CommonPermission}profile`);

export default pages.all;
