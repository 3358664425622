//Реакт
import React, { useEffect } from "react";
//Компоненты
import Select from "../../../../components/inputs/select"
import MessageErrors from "../../../../components/message/__errors/message__errors";
//Mui Icons
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
//Стили
import './styles.css';

export default function Certificate({value = '', options, children, placeholder, className = '', errors, onCheck, onChange, ...other}){
    useEffect(() => {
        console.log(options)
    }, [options])
    const handleSelectCertificate = (e) => {
        const selectedKey = e.target.value;
        onChange({target: {value: options[selectedKey]}});
    }
    const handleTextChange = (e) => {
        const selectedKey = null
        onChange({target: {value: e.target.value}});
    }
    if(placeholder === undefined) placeholder = children;
    return <>
        <div className={`certificate ${className}`}>
            <Select 
                className="certificate-input" 
                options={options} 
                value={Object.keys(options).find(key => options[key] === value)} 
                onChange={handleSelectCertificate} 
                onTextChange={handleTextChange}
                placeholder="Номер сертификата"
            />
            <button onClick={onCheck} className="certificate-button__search"><SearchOutlinedIcon></SearchOutlinedIcon></button>
        </div>

        {errors ? <MessageErrors errors={errors} sx={{width: "100%"}}/> : null}
    </>
}