//Реакт
import React, { useState, useContext, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
//Библиотеки и Api
import ApiCategory from "../../../../../app/api/authorized/admin/content/ApiCategory";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Компоненты
import InputText from "../../../../components/inputs/text";
import InputWrapper from "../../../../components/input/__wrapper";
import InputDatetime from "../../../../components/inputs/datetime/datetime";
import Select from "../../../../components/inputs/select";
//Стили
import './styles.css';

export default function CategoryShow({ ...other}){
  return <Page ApiModel={ApiCategory} { ...other}>
    <CategoryShowContent { ...other}/>
  </Page>
}

function CategoryShowContent({ ...other}){
  const contextPage = useContext(ContextPage);
  const contextContent = useContext(ContextContent);
  const [_id, set_id] = useState(null);
  const [_name, set_name] = useState('');
  const [_slug, set_slug] = useState(null);
  const [_bookingBalanceTypeId, set_bookingBalanceTypeId] = useState(null);
  const [_isPromotion, set_isPromotion] = useState(null);
  const [_created, set_created] = useState(null);
  const [_updated, set_updated] = useState(null);

  const bookingBalanceTypes = useSelector(state => state.adminOption.bookingBalanceTypes);
  const handleBookingBalanceTypeChange = (event) => {
        set_bookingBalanceTypeId(event.target.value);
  }

  const handleIsPromotionChange = (event) => {
        set_isPromotion(event.target.value);
  }

  contextContent._dataToServer.current = {
    id: _id,
    name: _name,
    slug: _slug,
    is_promotion: _isPromotion,
    balance_type_id: _bookingBalanceTypeId,
  };
  useLayoutEffect(()=>{
    contextContent._handleSetData.current = (data) =>{
      set_id(data.id);
      set_name(data.name);
      set_slug(data.slug);
      set_isPromotion(data.is_promotion);
      set_bookingBalanceTypeId(data?.balance_type?.balance_type_id);
      set_created(data.created_at);
      set_updated(data.updated_at);
      contextPage.setTitle(`Категория:  ${data.name}`);
      contextContent.setTitle(`Категория:  ${data.name}`);
      contextPage.setName(data.name);
    };
    contextContent.doLoad();
  },[]);

  return <>
    {!contextContent.isCreate ?
    <InputWrapper>
      <InputText className="pages__show-input" value={_id} readOnly={true}>Номер</InputText>
    </InputWrapper> 
    : null }
    <InputWrapper>
      <InputText className="pages__show-input" value={_slug} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_slug(e.target.value)}>Слаг</InputText>
    </InputWrapper> 
    <InputWrapper>
      <InputText className="pages__show-input" value={_name} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_name(e.target.value)}>Категория</InputText>
    </InputWrapper>
    <InputWrapper>
        <Select placeholder="Типы депозитов ..." className="pages__show-input" options={bookingBalanceTypes} value={_bookingBalanceTypeId} readOnly={!contextContent.isCreateOrEdit} onChange={handleBookingBalanceTypeChange}>Тип депозита для возврата</Select>
    </InputWrapper>
    <InputWrapper>
        <Select className="pages__show-input" options={["Без акции", "Акция"]} value={_isPromotion} readOnly={!contextContent.isCreateOrEdit} onChange={(event) => {set_isPromotion(event.target.value)}}>Акция</Select>
    </InputWrapper>
    {!contextContent.isCreate ? 
      <InputWrapper>
        <InputDatetime className="pages__show-input" value={_created} readOnly={true}>Создан</InputDatetime>
      </InputWrapper> 
    : null }
    {!contextContent.isCreate ? 
      <InputWrapper>
        <InputDatetime className="pages__show-input" value={_updated} readOnly={true}>Изменен</InputDatetime>
      </InputWrapper> 
    : null }
  </>
}
