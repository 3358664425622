//Реакт
import React, { useLayoutEffect, useContext, useState } from "react";
import { useSelector } from "react-redux";
//Библиотеки и Api
import ApiBalances from "../../../../../app/api/authorized/account/booking/ApiBalances";
import { route } from "../../../../../inc/Routes"
import ViewHandler from "../../../../../inc/ViewHandler"
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
import Modal from "../../../../components/modals/modal";
//Модули view
import List from "../../../../modules/list";
import ListHeaderItem from "../../../../modules/list/__header-item";
import ListBodyItem from "../../../../modules/list/__body-item";
//Компоненты
import InputText from "../../../../components/inputs/text"
import Select from "../../../../components/inputs/select";
//MUI Material
import BrowseGalleryOutlinedIcon from '@mui/icons-material/BrowseGalleryOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import NumbersIcon from '@mui/icons-material/Numbers';
import SegmentIcon from '@mui/icons-material/Segment';
//Стили
import './styles.css';
import Button from "../../../../components/button/button";

export default function AccountBalanceIndex({ ...other }) {
    return <Page {...other}>
        <AccountBalanceIndexContent {...other} />
    </Page>
}

function AccountBalanceIndexContent({ ...other }) {
    const contextContent = useContext(ContextContent);
    const contextPage = useContext(ContextPage);

    const [isModalPurchase, setIsModalPurchase] = useState(false);

    //Временное решение, далее планируется все делать через плитку
    const [_currentBalanceName, set_currentBalanceName] = useState('');
    const [_currentBalanceId, set_currentBalanceId] = useState(null);
    const [_currentBalanceMinutes, set_currentBalanceMinutes] = useState(null);
    const balanceOptions = useSelector(state => state.accountOption.availableProducts);
    const [_balanceOptionId, set_balanceOptionId] = useState(balanceOptions ? Object.keys(balanceOptions)[0] : null);
    
    const handlePurchaseModal = (id, name, balance) => {
        set_currentBalanceId(id);
        set_currentBalanceName(name);
        set_currentBalanceMinutes(balance);
        setIsModalPurchase(true);
    }

    const handleDropCurrentBalance = () => {
        set_currentBalanceName('');
        set_currentBalanceId(null);
        set_currentBalanceMinutes(null);
    }

    const handlePurchase = () => {
        const fetchData = async () => {
            try {
                const apiPurchase = ApiBalances.Update(_currentBalanceId, {product_id: _balanceOptionId});
                const responseData = await apiPurchase.getResponse();
                if(responseData.url) {
                    window.open(responseData.url);
                }
            } catch (error) {}
        };

        fetchData();

        set_balanceOptionId(balanceOptions ? Object.keys(balanceOptions)[0] : null);
        handleDropCurrentBalance();
        setIsModalPurchase(false);
    }

    const handleBalanceOptionChange = (event) => {
        set_balanceOptionId(event.target.value)
    }

    useLayoutEffect(() => {
        contextPage.setTitle(`Мои депозиты`);
        contextContent.setTitle(`Мои депозиты`);
    }, [contextContent, contextPage]);

    const header = [
        <ListHeaderItem
            key="type"
            name="type"
        >
            <div className="header-row__center">
                <MoreTimeIcon/>
                <div className="header-row__center_text">Баланс</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="balance"
            name="balance"
        >
            <div className="header-row__center">
                <TimelapseIcon/>
                <div className="header-row__center_text">Кол-во минут   </div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="reserved"
            name="reserved"
        >
            <div className="header-row__center">
                <AcUnitIcon/>
                <div className="header-row__center_text">Резерв</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="times"
            name="times"
            isSort={false}
        >
            <div className="header-row__center">
                <SegmentIcon/>
                <div className="header-row__center_text">Операции</div> 
            </div>
        </ListHeaderItem>,
        <ListHeaderItem
            key="id"
            name="id"
        >
            <div className="header-row__center">
                <NumbersIcon/>
                <div className="header-row__center_text">Номер</div> 
            </div>
        </ListHeaderItem>,
    ];

    const filters = [];

    const renderRow = (row, i) => {
        return [
            <ListBodyItem className="account-balances_column-width" key="type" data-label={ViewHandler.getComponentTextByClass(header[0].props.children, 'header-row__center_text')}>{ row.type_name }</ListBodyItem>,
            <ListBodyItem className="account-balances_column-width" key="balance" data-label={ViewHandler.getComponentTextByClass(header[1].props.children, 'header-row__center_text')}>{ row.balance }</ListBodyItem>,
            <ListBodyItem className="account-balances_column-width" key="reserved" data-label={ViewHandler.getComponentTextByClass(header[2].props.children, 'header-row__center_text')}>{ row.reserved }</ListBodyItem>,
            <ListBodyItem className="account-balances_column-width" key="times" data-label={ViewHandler.getComponentTextByClass(header[3].props.children, 'header-row__center_text')}>
                <div className="balances_times">
                    <div>
                        <a href={route("authorized.account.booking.times.index", {balanceId: row.id})}>
                            <BrowseGalleryOutlinedIcon></BrowseGalleryOutlinedIcon>
                        </a>
                    </div>
                    <div onClick={() => {handlePurchaseModal(row.id, row.type_name, row.balance )}} className="balances_times-icon">
                        <CreditCardOutlinedIcon></CreditCardOutlinedIcon>
                    </div>
                </div>
            </ListBodyItem>,
            <ListBodyItem className="account-balances_column-width" key="id" data-label={ViewHandler.getComponentTextByClass(header[4].props.children, 'header-row__center_text')}>{ row.id }</ListBodyItem>,
        ];
    }
    return <>
        <List
            header={header}
            filters={filters}
            ApiModel={ApiBalances}
            renderRow={renderRow}   
            searchParams={{}}
        />
        <Modal
            title={'Пополнение депозита'}
            isOpenModal={isModalPurchase}
            closeModal={()=>setIsModalPurchase(false)}
            className="balances_purchase-modal"
        >
            <div className="balances_purchase-modal-content">
                <InputText className="balances_purchase-modal-content_row" readOnly={true} value={_currentBalanceName}>Депозит</InputText>
                <InputText className="balances_purchase-modal-content_row" readOnly={true} value={_currentBalanceMinutes + ' мин'}>Баланс</InputText>
                <Select className="balances_purchase-modal-content_row" options={balanceOptions} value={_balanceOptionId} onChange={handleBalanceOptionChange}>Пополнить</Select>
                <Button onClick={handlePurchase} className="balances_purchase-modal-content_row--button">Пополнить</Button>
            </div>
        </Modal>
    </>
}
